import React from "react"

import { BlockWrapper } from "../blockWrapper"
import { PortableTextBlock } from "../../sanity/portableTextBlock"

const ColumnText = ({ blockConfig, layout, items }) => {
  let layoutClasses = '';
  if (layout === 2) {
    layoutClasses = '';
  } else if (layout === 3) {
    layoutClasses = 'lg:grid-cols-3';
  } else if (layout === 4) {
    layoutClasses = 'lg:grid-cols-4';
  }

  return (
    <BlockWrapper {...blockConfig} className={`px-gutter w-full prose xl:prose-lg max-w-none`}>
      <div className={`grid grid-cols-1 md:grid-cols-2 ${layoutClasses} grid-flow-row gap-x-6 md:gap-x-12`}>
        {items.map((item, index) => {
          return (
            <>
              <PortableTextBlock className="col-span-1" text={item.text} />
            </>
          );
        })}
      </div>
    </BlockWrapper>
  );
};

export default ColumnText
