import React, { useState, useEffect, useCallback } from "react"
import { useEmblaCarousel } from "embla-carousel/react"
import { setupWheelGestures } from "embla-carousel-wheel-gestures"

const DotButton = ({ selected, onClick }) => (
  <button
    className={`w-3 h-3 mx-2 rounded-full embla__dot ${selected ? 'bg-brand is-selected' : 'bg-white'}`}
    type="button"
    onClick={onClick}
  />
);
  

const DotsCarousel = ({ children, className }) => {
  const [scrollProgress, setScrollProgress] = useState(0);

  let width = window.innerWidth;
    let toScrollInit = 1;
    if (width < 768) {
      toScrollInit = 1;
    } else if (width < 1024) {
      toScrollInit = 2;
    } else if (width >= 1024) {
      toScrollInit = 3;
    }

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false,
    containScroll: 'keepSnaps',
    dragFree: false,
    slidesToScroll: toScrollInit,
  });


  const onScroll = useCallback(() => {
    if (!emblaApi) return;
    setScrollProgress(emblaApi.scrollProgress() * 100);
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      // Embla API is ready
      emblaApi.on('scroll', onScroll);
      emblaApi.on('resize', () => {
        /*
        - Check current breakpoint
        - Determine how many slides to scroll for this breakpoint
        - Store it in a variable called toScroll
        - Update Embla options
        */
        let width = window.innerWidth;
        let toScroll = 1;
        if (width < 768) {
          toScroll = 1;
        } else if (width < 1024) {
          toScroll = 2;
        } else if (width >= 1024) {
          toScroll = 3;
        }
        const options = { slidesToScroll: toScroll };
        emblaApi.reInit(options);
        console.log('resized');
      });
      setupWheelGestures(emblaApi);
    }
  }, [emblaApi, onScroll]);

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect();
    setScrollSnaps(emblaApi.scrollSnapList());
    emblaApi.on('select', onSelect);
  }, [emblaApi, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={`${className} block relative`}>
        <div
          className={`${className} overflow-x-hidden transform lg:-translate-x-4 lg:w-dotCarousel`}
          ref={emblaRef}
        >
          <div className="canvas flex">{children}</div>
          <div className="mt-8 mx-auto text-center">
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default DotsCarousel;
